import { AssignableUsersResponse, AvailableUsersOption } from '../../../generated';
import { UserInfo } from '../../models/user-info.model';

export class AssignableUsersMapper {
  public static mapToUserInfos(assignableUsers: AssignableUsersResponse): UserInfo[] {
    if (!assignableUsers) {
      return [];
    }

    return assignableUsers.options.map(obj => {
      return this.mapToUserInfo(obj);
    });
  }

  public static mapToUserInfo(option: AvailableUsersOption): UserInfo {
    return {
      userId: option.id,
      fullName: option.label,
      organization: null,
      photoId: option.photoId
    };
  }
}
