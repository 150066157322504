import { Injectable } from '@angular/core';
import { combineLatest, Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';
// app
import { DataInitializer } from 'shared/core/interfaces/data-initializer.interface';
import { LoaderService } from 'shared/core/services/loader-service';

@Injectable({
  providedIn: 'root'
})
export class AppService {
  public dataInitializers: (() => Observable<boolean>)[] = [];
  private appLoaded = true; //Change to false when there is at least one data initializer

  constructor(public loaderService: LoaderService) {}

  public registerDataInitializer(dataInitializer: DataInitializer) {
    this.dataInitializers.push(() => dataInitializer.initialize());
  }

  public loadData() {
    if (!this.appLoaded) {
      this.loaderService.startLoading();
      this.appLoaded = true;
      const dataInitializations = this.triggerDataInitializations();
      return combineLatest(dataInitializations).pipe(
        map(result => {
          this.loaderService.stopLoading();
          return !!result;
        })
      );
    } else {
      return of(true);
    }
  }

  private triggerDataInitializations(): Observable<boolean>[] {
    const result: Observable<boolean>[] = [];
    this.dataInitializers.forEach(initializer => result.push(initializer()));
    return result;
  }

  public unloadData() {
    this.appLoaded = false;
  }
}
