import { VerificationsChatTopicInfo } from 'app/models/verifications-chat-topic-info.model';

export enum BalAction {
  GetChatSessionBal = '[Get the chat session for BAL] Gets the chat session for BAL for a specific chat id',
  UpdateChatVerificationBal = '[Update verification in BAL] Updates verification request for a specific verification id'
}

export class GetChatSessionBal {
  public static readonly type = BalAction.GetChatSessionBal;

  constructor(public payload: { chatId: string }) {}
}

export class UpdateChatVerificationBal {
  public static readonly type = BalAction.UpdateChatVerificationBal;

  constructor(
    public payload: {
      verificationsChatTopicInfo: VerificationsChatTopicInfo;
    }
  ) {}
}
