export enum SettingsAction {
  SET_USER_INPUT_DISABLED = '[Settings] Set User Input Disabled',
  SET_SHOW_JUMPING_DOTS = '[Settings] Set Show Jumping Dots',
  SET_CHAT_LANGUAGE = '[Settings] Set Chat Language',
  SET_AVAILABLE_MODELS = '[Settings] Set available Models',
  SET_AVAILABLE_LANGUAGES = '[Settings] Set available Languages',
  SET_AVAILABLE_CONTEXT = '[Settings] Set available Context',
  SET_AVAILABLE_COLLECTIONS = '[Settings] Set available Collections',
  SET_SWITCHING_CHAT_AVAILABLE = '[Settings] Set Switching Chat Available',
  SET_SELECTED_CONTEXT = '[Settings] Set selected Context',
  SET_SELECTED_MODEL = '[Settings] Set selected Model',
  SET_TESTER_MODE = '[Settings] Set Tester Mode',
  IS_MOBILE_VERSION = '[Settings] Is Mobile Version',
  STORE_CHATBOT_CONFIGURATION_ID = '[Settings] Store Chatbot Configuration Id',
  SET_COLLECTION_ID = '[Settings] Set Collection Id'
}

export class SetUserInputDisabled {
  public static readonly type = SettingsAction.SET_USER_INPUT_DISABLED;

  constructor(public payload: boolean) {}
}

export class SetShowJumpingDots {
  public static readonly type = SettingsAction.SET_SHOW_JUMPING_DOTS;

  constructor(public payload: boolean) {}
}

export class SetChatLanguage {
  public static readonly type = SettingsAction.SET_CHAT_LANGUAGE;

  constructor(public payload: string) {}
}

export class SetAvailableLanguages {
  public static readonly type = SettingsAction.SET_AVAILABLE_LANGUAGES;

  constructor(public payload: { language: string; viewLanguage: string }[]) {}
}

export class SetAvailableContext {
  public static readonly type = SettingsAction.SET_AVAILABLE_CONTEXT;

  constructor(public payload: string[]) {}
}

export class SetAvailableModels {
  public static readonly type = SettingsAction.SET_AVAILABLE_MODELS;

  constructor(public payload: { id: string; name: string }[]) {}
}

export class SetAvailableCollections {
  public static readonly type = SettingsAction.SET_AVAILABLE_COLLECTIONS;

  constructor(public payload: any) {}
}

export class SetSwitchingChatAvailable {
  public static readonly type = SettingsAction.SET_SWITCHING_CHAT_AVAILABLE;

  constructor(public payload: boolean) {}
}

export class SetSelectedContext {
  public static readonly type = SettingsAction.SET_SELECTED_CONTEXT;

  constructor(public payload: string) {}
}

export class SetSelectedModel {
  public static readonly type = SettingsAction.SET_SELECTED_MODEL;

  constructor(public payload: string) {}
}

export class SetTesterMode {
  public static readonly type = SettingsAction.SET_TESTER_MODE;

  constructor(public payload: boolean) {}
}

export class IsMobileVersion {
  public static readonly type = SettingsAction.IS_MOBILE_VERSION;

  constructor(public payload: boolean) {}
}

export class StoreChatbotConfigurationId {
  static readonly type = SettingsAction.STORE_CHATBOT_CONFIGURATION_ID;

  constructor(public payload: string) {}
}

export class StoreCollectionId {
  static readonly type = SettingsAction.SET_COLLECTION_ID;

  constructor(public payload: string) {}
}
