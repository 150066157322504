export enum ChatbotConfigurationsAction {
  LoadChatbotConfigurations = '[Load Chatbot Configurations] Loads all accessible chatbot configurations',
  LoadChatbotConfigurationPhoto = '[Load Chatbot Configuration Photo] Load chatbot configuration photo'
}

export class LoadChatbotConfigurations {
  public static readonly type = ChatbotConfigurationsAction.LoadChatbotConfigurations;
}

export class LoadChatbotConfigurationPhoto {
  public static readonly type = ChatbotConfigurationsAction.LoadChatbotConfigurationPhoto;

  constructor(public payload: { photoId: string }) {
  }
}
