import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ChatSession } from '../../../models/chatbot-response';

import { Observable } from 'rxjs';
import { catchError, map } from 'rxjs/operators';

import { getConfig } from 'shared/app';
import { ErrorTranslationService } from './error-translation.service';

@Injectable({
  providedIn: 'root'
})
export class ConversationLogService {
  constructor(private httpClient: HttpClient, private errorTranslate: ErrorTranslationService) {}

  changeChat(id: string): Observable<ChatSession> {
    const apiUrl = this.apiUrl();
    return this.httpClient.get<ChatSession>(apiUrl + 'session/' + id).pipe(
      catchError((error: HttpErrorResponse) => {
        console.log(error);
        return this.errorTranslate.translateConversationEditError(error);
      })
    );
  }

  deleteChat(id: string): Observable<{ deleted: boolean; error: string }> {
    const apiUrl = this.apiUrl();
    return this.httpClient.delete<boolean>(apiUrl + 'session/' + id).pipe(
      map(response => {
        return {
          deleted: response,
          error: ''
        };
      }),
      catchError((error: HttpErrorResponse) => {
        console.log(error);
        return this.errorTranslate.translateConversationDeleteError(error);
      })
    );
  }

  editChat(id: string, name: string): Observable<ChatSession> {
    const apiUrl = this.apiUrl();
    return this.httpClient
      .patch<ChatSession>(apiUrl + 'session/' + id, { title: name })
      .pipe(
        catchError((error: HttpErrorResponse) => {
          console.log(error);
          return this.errorTranslate.translateConversationEditError(error);
        })
      );
  }

  private apiUrl(): string {
    return `${getConfig('apiUrl')}/history/`;
  }
}
