import { cloneDeep } from 'lodash-es';
import { StateContext } from '@ngxs/store';
import { catchError, tap } from 'rxjs/operators';
import { throwError } from 'rxjs';

import { ChatBalApiService } from 'generated';
import { BalStateModel } from '../chat-bal.state';
import { GetChatSessionBal, UpdateChatVerificationBal } from '../chat-bal.action';
import { StoreValueStatus } from 'app/models/store-value-status.model';
import { ChatSessionBalMapper } from 'app/domain/mappers/chat-session-bal-mapper';
import { ChatPreviewMapper } from 'app/domain/mappers/chat-preview-mapper';

export class BalUseCases {
  constructor(private chatBalApiService: ChatBalApiService) {}

  public getChatSession(context: StateContext<BalStateModel>, action: GetChatSessionBal) {
    context.patchState({
      chatSessionLoadStatus: StoreValueStatus.LOADING
    });
    return this.chatBalApiService.getBalChat(action.payload.chatId).pipe(
      tap(response => {
        context.patchState({
          chatSession: ChatSessionBalMapper.mapToChatSessionBal(response),
          chatSessionLoadStatus: StoreValueStatus.LOADED
        });
      }),
      catchError(err => {
        context.patchState({
          chatSessionLoadStatus: StoreValueStatus.LOADED,
          errorMessage: 'This is an example of error <b>message</b>'
        });
        return throwError(err);
      })
    );
  }

  public updateChatVerificationBal(
    context: StateContext<BalStateModel>,
    action: UpdateChatVerificationBal
  ) {
    let updatedChatSession = cloneDeep(context.getState().chatSession);

    const storeVerificationRequest = updatedChatSession.verificationRequests.find(
      verificationRequest =>
        verificationRequest.chatMessageId ===
        action.payload.verificationsChatTopicInfo.chatMessageId
    );

    if (storeVerificationRequest) {
      const updatedVerificationRequest = ChatSessionBalMapper.mapVerificationsChatTopicInfoToVerificationRequest(
        action.payload.verificationsChatTopicInfo
      );
      ChatSessionBalMapper.copyVerificationRequestValues(
        storeVerificationRequest,
        updatedVerificationRequest
      );

      const updatedEditMessage = ChatPreviewMapper.mapToChatVerificationPreviewEditMessage(
        action.payload.verificationsChatTopicInfo.editMessage
      );

      if (updatedEditMessage) {
        let storeChatbotMessage = updatedChatSession.chatHistory.find(
          message => message.messageId === updatedVerificationRequest.chatMessageId
        );
        if (storeChatbotMessage) {
          storeChatbotMessage.editMessage = updatedEditMessage;
        }
      }

      context.patchState({
        chatSession: updatedChatSession
      });
    }
  }
}
