import { TemplatePickerOption } from '@apiax/web-commons';
import { UserInfo } from '../../models/user-info.model';
import { PhotoDAO } from '../daos/photo.dao';

export class TemplatePickerOptionMapper {
  static mapToTemplatePickerOption(option: UserInfo): TemplatePickerOption {
    return {
      id: option.userId,
      label: option.fullName,
      image: PhotoDAO.getPhotoUrl(option.photoId)
    };
  }
}
