import { Routes } from '@angular/router';
import { AuthGuard } from 'shared/app/guards';

export const ROUTES: Routes = [
  {
    canActivate: [AuthGuard],
    path: 'chat',
    loadChildren: () => import('./chatbot/chatbot.module').then(m => m.ChatbotModule)
  },
  {
    canActivate: [AuthGuard],
    path: 'chat-verifications',
    loadChildren: () =>
      import('./presentation/chat-verifications/chat-verifications.module').then(
        m => m.ChatVerificationsModule
      )
  },
  {
    canActivate: [AuthGuard],
    path: 'chat-bal',
    loadChildren: () => import('./presentation/chat-bal/chat-bal.module').then(m => m.ChatBalModule)
  },
  // This needs to be last, otherwise no redirect will work
  {
    canActivate: [AuthGuard],
    path: '',
    loadChildren: () =>
      import('./presentation/landing-page/landing-page.module').then(m => m.LandingPageModule)
  }
];
