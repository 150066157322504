export enum ErrorAction {
  UpdateChatErrorMessage = '[Update Error Message] Updates Error Message'
}

export class UpdateErrorMessage {
  public static readonly type = ErrorAction.UpdateChatErrorMessage;

  constructor(public payload: { exceptionName: string }) {}
}

