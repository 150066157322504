import { getConfig } from 'shared/app';

export class PhotoDAO {
  public static getPhotoUrl(photoId: string): string {
    return photoId ? this.getUrl(photoId) : null;
  }

  private static get basePath() {
    return `${getConfig('userPhotoBaseUrl')}`;
  }

  private static getUrl(id: string) {
    return `${this.basePath}${id}`;
  }
}
