/**
 * chat-java-api - Web
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { RegPropWithOptions } from './regPropWithOptions';
import { Ruleset } from './ruleset';
import { Term } from './term';
import { EditMessage } from './editMessage';
import { Span } from './span';
import { Result } from './result';


export interface ChatMessageBal { 
    chatMessageId?: string;
    role?: ChatMessageBal.RoleEnum;
    content?: string;
    referencedSpans?: Array<Span>;
    terms?: Array<Term>;
    edit?: EditMessage;
    regprop?: RegPropWithOptions;
    result?: Result;
    evaluationInformation?: { [key: string]: string; };
    evaluatedRulesets?: Array<Ruleset>;
}
export namespace ChatMessageBal {
    export type RoleEnum = 'USER' | 'ASSISTANT';
    export const RoleEnum = {
        USER: 'USER' as RoleEnum,
        ASSISTANT: 'ASSISTANT' as RoleEnum
    };
}


