import { VerificationStatus } from 'app/models/verification-status.model';
import { EditMessage } from 'app/models/verifications-chat-topic-info.model';
import { Verification } from 'app/models/verifications-configuration-topic-info.model';

export enum ChatPreviewAction {
  FETCH_CHAT_PREVIEWS = '[Fetch Chat Previews] Fetches chat previews for a conversation',
  CHANGE_CONVERSATION = '[Change Conversation] Changes the conversation',
  DELETE_CONVERSATION = '[Delete Conversation] Deletes a conversation',
  EDIT_CONVERSATION = '[Edit Conversation] Edits a conversation',
  ADD_NEW_VERIFICATION_REQUEST_TO_CHAT_PREVIEW = '[Add New Verification Request To Chat Preview] Adds a new verification request to the chat preview',
  CLEAR_CHAT_PREVIEWS = '[Clear Chat Previews] Clears the chat previews',
  UPDATE_VERIFICATION_REQUEST_ON_CHAT_PREVIEW = '[Update Verification Request on Chat Preview] Updates a verification request on the chat preview',
  RESET_CHAT_PREVIEW_HAS_UPDATE = '[Reset Chat Preview Has Update] Resets the has update of a chat preview',
  UPDATE_CHAT_PREVIEW_ACTIVITY = '[Update Chat Preview Activity] Updates a chat preview activity',
  UPDATE_CHAT_PREVIEW_TITLE = '[Update Chat Preview Title] Updates a chat preview title'
}

export class ClearChatPreviews {
  public static readonly type = ChatPreviewAction.CLEAR_CHAT_PREVIEWS;
}

export class FetchChatPreviews {
  public static readonly type = ChatPreviewAction.FETCH_CHAT_PREVIEWS;
}

export class ChangeConversation {
  public static readonly type = ChatPreviewAction.CHANGE_CONVERSATION;

  constructor(
    public payload: {
      chatId: string;
    }
  ) {
  }
}

export class DeleteConversation {
  public static readonly type = ChatPreviewAction.DELETE_CONVERSATION;

  constructor(
    public payload: {
      chatId: string;
    }
  ) {
  }
}

export class EditConversation {
  public static readonly type = ChatPreviewAction.EDIT_CONVERSATION;

  constructor(public payload: { chatId: string; name: string }) {
  }
}

export class AddNewVerificationRequestToChatPreview {
  public static readonly type = ChatPreviewAction.ADD_NEW_VERIFICATION_REQUEST_TO_CHAT_PREVIEW;

  constructor(public payload: { chatId: string; chatMessageId: string; verificationId: string }) {
  }
}

export class UpdateVerificationRequestOnChatPreview {
  public static readonly type = ChatPreviewAction.UPDATE_VERIFICATION_REQUEST_ON_CHAT_PREVIEW;

  constructor(public payload: { chatId: string; verificationId: string; chatMessageId: string; status: VerificationStatus, editMessage?: EditMessage, verification?: Verification }) {}
}

export class ResetChatPreviewHasUpdate {
  public static readonly type = ChatPreviewAction.RESET_CHAT_PREVIEW_HAS_UPDATE;

  constructor(public payload: { chatId: string }) {
  }
}

export class UpdateChatPreviewActivity {
  public static readonly type = ChatPreviewAction.UPDATE_CHAT_PREVIEW_ACTIVITY;

  constructor(
    public payload: {
      chatId: string;
      active: boolean;
      hasUpdate: boolean;
    }
  ) {
  }
}

export class UpdateChatPreviewTitle {
  public static readonly type = ChatPreviewAction.UPDATE_CHAT_PREVIEW_TITLE;

  constructor(
    public payload: {
      chatId: string;
      title: string;
    }
  ) {
  }
}
