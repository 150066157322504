import { EditMessageModel } from 'app/models/edit-message.model';
import { Feedback } from '../../../chatbot/feedback/feedback';
import { ChatbotMessage } from '../../../models/chatbot-message';
import { ChatbotResponse, MessageReference, MessageReferencedSpans } from '../../../models/chatbot-response';

export enum ChatAction {
  SET_CHAT_ID = '[ChatLog] Set Chat Id',
  SEND_USER_MESSAGE = '[Chat] Send User Message',
  STORE_CHATBOT_MESSAGES = '[Chat] Store ChatBot Message',
  STORE_USER_MESSAGE = '[Chat] Store User Message',
  STORE_CHATBOT_REFERENCES = '[Chat] Store ChatBot References',
  UPDATE_CHATBOT_MESSAGES = '[Chat] Update ChatBot Messages',
  STORE_FEEDBACK_ITEM = '[Chat] Store Feedback Item',
  STORE_REFERENCES_FEEDBACK = '[Chat] Set References Feedback',
  UPDATE_REFERENCES_CONTEXT = '[Chat] Update References Context',
  CLEAR_CHATBOT_MESSAGES = '[Chat] Clear ChatBot Messages',
  ADD_EDIT_MESSAGE_TO_CHAT_MESSAGE = '[Chat] Add Edit Message to a Chat Message',
}

export class SetChatId {
  public static readonly type = ChatAction.SET_CHAT_ID;

  constructor(public payload: string) {
  }
}

export class SendUserMessage {
  public static readonly type = ChatAction.SEND_USER_MESSAGE;

  constructor(
    public payload: {
      messageWrapper: ChatbotMessage;
      invokedBySendMessage?: boolean;
    }
  ) {
  }
}

export class StoreChatBotMessage {
  public static readonly type = ChatAction.STORE_CHATBOT_MESSAGES;

  constructor(public payload: ChatbotResponse) {
  }
}

export class StoreUserMessage {
  public static readonly type = ChatAction.STORE_USER_MESSAGE;

  constructor(public payload: { message: string; messageId: string; type: 'text' | 'error' }) {
  }
}

export class UpdateChatBotMessage {
  public static readonly type = ChatAction.UPDATE_CHATBOT_MESSAGES;

  constructor(public payload: ChatbotResponse) {
  }
}

export class StoreChatBotReferences {
  public static readonly type = ChatAction.STORE_CHATBOT_REFERENCES;

  constructor(public payload: MessageReference) {
  }
}

export class StoreFeedbackItem {
  public static readonly type = ChatAction.STORE_FEEDBACK_ITEM;

  constructor(public payload: { messageId: string; feedback: Feedback }) {
  }
}

export class StoreFeedbackReference {
  public static readonly type = ChatAction.STORE_REFERENCES_FEEDBACK;

  constructor(public payload: { feedback: Feedback; messageId: string; documentid: string }) {
  }
}

export class UpdateReferencesContext {
  public static readonly type = ChatAction.UPDATE_REFERENCES_CONTEXT;

  constructor(public payload: { context: MessageReferencedSpans[]; referenceId: string }) {
  }
}

export class ClearChatBotMessages {
  public static readonly type = ChatAction.CLEAR_CHATBOT_MESSAGES;
}

export class CancelChatRequests {
  public static readonly type = ChatAction.CLEAR_CHATBOT_MESSAGES;
}

export class AddEditMessageToChatMessage {
  public static readonly type = ChatAction.ADD_EDIT_MESSAGE_TO_CHAT_MESSAGE;

  constructor(public payload: { messageId: string; editMessage: EditMessageModel }) {
  }
}
